<script setup lang="ts">
  import ProductVariation from './ProductVariation.vue'
  import { ref, computed, watch } from 'vue'
  import { useCatalogStore } from '@/stores/catalogStore'
  import { useCartStore } from '@/stores/cartStore'
  import { analytics } from '@/firebase'
  import { logEvent } from 'firebase/analytics'
  import { useAnalyticsItemGenerators } from '@/utilities/analytics/AnalyticsItemGenerators'

  const { addToCart } = useCartStore()
  const { findProductBySku } = useCatalogStore()
  const { generateAnalyticsItem } = useAnalyticsItemGenerators()

  const props = defineProps<{
    initialSku: string
  }>()

  const pageSku = ref(props.initialSku)

  const item = computed(() => {
    return findProductBySku(pageSku.value)
  })

  const variation = computed(() => {
    return item.value?.variation?.[0] ?? null
  })

  const subvariation = computed(() => {
    return item.value?.variation?.[1] ?? null
  })

  function applySkuMask(mask: string) {
    let newSku = Array.from(pageSku.value)
    for (let i = 0; i < mask.length; i++) {
      if (mask[i] != '-') {
        newSku[i] = mask[i]
      }
    }
    pageSku.value = newSku.join('')
  }

  watch(item, () => {
    if (item.value) {
      const anItem = generateAnalyticsItem(item.value.sku, 1)

      if (anItem) {
        console.log('select_item analytics', analytics)
        logEvent(analytics, 'select_item', {
          item_list_id: 'configurator',
          item_list_name: 'Product Configurator',
          items: [anItem],
        })
      }
    }
  })
</script>
<template>
  <div
    id="purchaseBlock"
    class="flex-1 px-10 md:p-10 lg:p-0">
    <div class="font-light text-sm">
      <RouterLink to="/"><span class="pr-2">Method</span></RouterLink> •
      <RouterLink to="/"><span class="px-2">Awaken</span></RouterLink>
    </div>
    <div class="pt-7 pb-1 text-3xl font-medium tracking-tight">
      <slot name="productTitle"></slot>
    </div>

    <div class="pt-12 pb-7 text-sm font-light">
      <slot name="oneLineDescription"></slot>
    </div>

    <div class="border-solid border-t-[5px] border-stone-400 w-full">
      <div class="text-sm py-5 border-solid border-b border-stone-400 last:border-0">
        <div class="font-bold uppercase">Active Awareness</div>
        <div class="pt-1 font-light">
          <slot name="activeAwareness"></slot>
        </div>
      </div>
      <div class="text-sm py-5 border-solid border-b border-stone-400 last:border-0">
        <div class="font-bold uppercase">Ingredients</div>
        <div class="pt-1 font-light">
          <slot name="ingredients"></slot>
        </div>
      </div>
      <div
        v-if="item && item.name == 'Tabs' && variation"
        class="flex-1 py-5 px-">
        <div class="font-bold font-ringside-condensed text-stone-400 uppercase text-sm pb-2.5">Choose your space:</div>
        <div class="flex gap-5">
          <div class="flex-1 flex flex-col gap-2.5 text-xs text-stone-400">
            <ProductVariation
              :selection="variation"
              title="Headspace"
              description="Curious, Creative, Connected"
              skuMask="--H-"
              image="ATH"
              @variation-change="applySkuMask"></ProductVariation>
            <ProductVariation
              :selection="variation"
              title="Bodyspace"
              description="Emotional, Sensory, Grounded"
              skuMask="--B-"
              image="ATB"
              @variation-change="applySkuMask"></ProductVariation>
            <ProductVariation
              :selection="variation"
              title="Balanced"
              description="A blend of Headspace & Bodyspace"
              skuMask="--0-"
              image="AT0"
              @variation-change="applySkuMask"></ProductVariation>
            <div class="font-bold font-ringside-condensed uppercase text-sm pt-5 text-stone-400">Select a mode:</div>

            <div class="flex">
              <ProductSubvariation
                :selection="subvariation"
                skuMask="---A"
                title="A"
                description="Traditional"
                subtext="4 - 6hr"
                @variation-change="applySkuMask"
                class="rounded-tl-lg rounded-bl-lg"
                ><template #formatting>
                  <div>4hr</div>
                </template>
              </ProductSubvariation>
              <ProductSubvariation
                :selection="subvariation"
                skuMask="---B"
                title="B"
                description="Faster, Intense"
                subtext="2 - 3hr"
                @variation-change="applySkuMask"
                class="rounded-tr-lg rounded-br-lg"
                ><template #formatting>
                  <div>2hr</div>
                </template>
              </ProductSubvariation>
            </div>
          </div>
        </div>
      </div>
      <div class="flex border-solid border-b border-stone-400 last:border-0">
        <template v-if="item && item.price">
          <BigPriceBlock class="flex-1 content-end pt-10">${{ item.price }}</BigPriceBlock>
        </template>
        <div class="grow content-end relative -top-1 text-xs uppercase text-stone-500 font-normal text-right">
          <slot name="servingsPer"></slot><span class="hidden md:inline">&ensp;●&ensp;</span
          ><br class="md:hidden" /><slot name="supply"></slot>
        </div>
      </div>
    </div>
    <BigButtonBlock
      v-if="item"
      @click="addToCart(item.sku)"
      >Add to your cart</BigButtonBlock
    >
  </div>
</template>
