<script setup lang="ts">
  import { ref, computed, onMounted } from 'vue'

  const props = defineProps<{
    title: string
    description: string
    selection: string
    skuMask: string
    image?: string
  }>()

  const emit = defineEmits(['variation-change'])

  const handleClick = () => {
    emit('variation-change', props.skuMask)
  }

  const imageUrl = ref('')

  onMounted(async () => {
    if (props.image) {
      try {
        const imageModule = await import(`@/assets/images/${props.image}.webp`)
        imageUrl.value = imageModule.default
        console.log('Loaded image URL:', imageModule.default) // Debug log
      } catch (error) {
        console.error('Failed to load image:', error)
      }
    }
  })

  const imagePath = computed(() => imageUrl.value)
</script>

<template>
  <div @click="handleClick">
    <div
      class="flex-1 flex items-center gap-5 box-border border border-solid border-stone-300 hover:border-stone-400 cursor-pointer rounded-lg pr-5"
      :class="title == selection ? 'border-2 border-solid border-stone-500 bg-stone-50 shadow-inner' : ''">
      <div class="flex-1 p-5">
        <div class="font-ringside-narrow font-bold uppercase text-xs text-stone-500 pb-0.5">
          {{ title }}
        </div>
        <div class="text-xs">{{ description }}</div>
      </div>
      <div
        v-if="image"
        class="aspect-square w-12 bg-center bg-no-repeat bg-contain rounded-full opacity-75 drop-shadow-md"
        :style="{ backgroundImage: `url(${imagePath})` }"
        :aria-label="title"></div>
    </div>
  </div>
</template>

<style scoped></style>
